import _ from 'lodash';
import omit from 'lodash/fp/omit';
import { $apply, $each, $set, update } from 'qim';

import { singular } from '@zapier/common-utils';

import {
  ACTION_TYPES,
  APP_INTENTION,
  APP_STATUSES,
  INPUT_FIELDS_SELECTOR,
  KEY_SUFFIX,
  NEW_SERVICE_FIELD,
  visibilityValues,
} from 'app/developer-v3/constants';
import { getStatus, normalizeStatusName } from 'app/developer-v3/utils/status';

import type {
  AppFormType,
  AppService,
  AppType,
  BackendAppType,
  FormFieldTemplate,
  FormifiedFieldSchema,
  FormifiedSettings,
  ObjectType,
  OperationMethodType,
  PartialDefinition,
} from 'app/developer-v3/types';
import type {
  ServiceType,
  ServiceSettings,
} from 'app/developer-v3/types/service';
import {
  AuthenticationSchema,
  AuthenticationType,
} from 'app/developer-v3/platformSchema/authentication';
import type {
  FieldOrFunctionSchema,
  FieldSchema,
} from 'app/developer-v3/platformSchema/field';
import type { RequestSchema } from 'app/developer-v3/platformSchema/request';

const FORMATIC_INPUT_OPTIONS = ['required', 'list', 'altersDynamicFields'];
const FIELD_CHILDREN_EXCLUSIONS = [
  'list',
  'dict',
  'type',
  'placeholder',
  'helpText',
  'default',
];

/**
 * Adds `readOnly` property for formatic form fields
 */
const setReadOnly = (fields: FormFieldTemplate[], isDisabled = true) =>
  fields.map<FormFieldTemplate>(field => ({ ...field, readOnly: isDisabled }));

const normalizeFieldTypes = (formValues: FormifiedFieldSchema) => {
  if (formValues.type === 'dict') {
    return {
      ..._.omit(formValues, ['type', 'options.list']),
      dict: true,
    };
  }

  return formValues;
};

const flattenOptions = (field: FormifiedFieldSchema) => {
  const { options, ...rest } = field;

  return {
    ...rest,
    ...options,
  };
};

function stringifyDropdown(field) {
  const dynamic = _.get(field, ['dynamic']);

  if (!dynamic) {
    return field;
  }

  const { source, name = '', label } = dynamic;

  return {
    ...field,
    dynamic: [source, name, label].filter(_.isString).join('.'),
  };
}

const isEmptyValue = value => _.isString(value) && !value;

// More hoops to jump through as the API does not accept some empty values
function removeEmpties(values) {
  const copy = _.cloneDeep(values);

  _.forEach(copy, (value, key) => {
    if (_.isObject(value)) {
      copy[key] = removeEmpties(value);
    } else if (isEmptyValue(value)) {
      delete copy[key];
    }
  });

  return copy;
}

const transformChildrenToSchemaRecursively = field => {
  if (!field.children) {
    return field;
  }

  return {
    ..._.omit(field, FIELD_CHILDREN_EXCLUSIONS),
    /* eslint-disable-next-line no-use-before-define */
    children: field.children.map(transformInputToSchema),
  };
};

const transformInputToSchema = _.flow(
  normalizeFieldTypes,
  flattenOptions,
  stringifyDropdown,
  removeEmpties,
  transformChildrenToSchemaRecursively
);

// There are some hoops to jump through to get the AppSchema to adhere to the UX for GUI integrations.
// In this case, dict needs to be a selectable field under types, but in the schema, it is a
// separate property altogether. This way, we can coerce properties in the schema, to conform
// to the new UX and transform back into the shape it needs to be when saving.
// https://github.com/zapier/zapier-platform/blob/main/packages/schema/docs/build/schema.md#fieldschema
const consolidateTypeField = (fields: FieldOrFunctionSchema | {}) => {
  const formValues = _.omit(fields, 'dict');

  if (fields.dict) {
    return {
      ...formValues,
      type: 'dict',
    };
  }

  formValues.default = fields.default || '';

  return formValues;
};

// Formatic nests checkbox fields under an options namespace. Since the fields coming from the app schema
// are flat, we separate them here. Shouldn't need this if we drop Formatic, but here for now.
const addFormOptions = (inputField: FieldOrFunctionSchema) => {
  const fieldOptions = FORMATIC_INPUT_OPTIONS.reduce((all, option) => {
    all[option] = !!inputField[option];
    return all;
  }, {});

  return update(['options', $set(fieldOptions)], inputField);
};

const parseDropdown = inputField => {
  const dropdown = _.get(inputField, ['dynamic']);

  if (!dropdown) {
    return inputField;
  }

  const [source, name, label] = dropdown.split('.');

  return {
    ...inputField,
    dynamic: { source, name, label },
  };
};

const transformChildrenToFormaticRecursively = field => {
  if (!field.children) {
    return field;
  }

  return {
    ...field,
    /* eslint-disable-next-line no-use-before-define */
    children: field.children.map(transformInputToFormatic),
  };
};

const transformInputToFormatic = _.flow(
  consolidateTypeField,
  parseDropdown,
  addFormOptions,
  omit(FORMATIC_INPUT_OPTIONS),
  transformChildrenToFormaticRecursively
);

const getInputFields = (service: AppService): FieldOrFunctionSchema[] =>
  _.get(service, INPUT_FIELDS_SELECTOR, []);

const getInputFieldIndex = (service: AppService, inputKey) => {
  const parsed = parseInt(inputKey, 10);
  return Number.isInteger(parsed) ? parsed : getInputFields(service).length - 1;
};

const getInputField = (service: AppService, inputKey: string | number) => {
  const index = getInputFieldIndex(service, inputKey);
  const field = getInputFields(service)[index] || {};
  return field;
};

const getDynamicInput = (service: AppService, inputKey: string | number) => {
  const index = getInputFieldIndex(service, inputKey);
  const field = getInputFields(service)[index] || {};

  return String(field.source || '');
};

const getPerformFromService = (service: AppService) =>
  _.get(service, ['operation', 'perform'], {});

const updateServiceInputFields = (service, fields: FieldOrFunctionSchema[]) => {
  return update([...INPUT_FIELDS_SELECTOR, $set(fields)], service);
};

const mapFormToSettings = (settings: FormifiedSettings): ServiceSettings => {
  const { key, name, noun, description, directions, visibility } = settings;

  return {
    key,
    noun,
    display: {
      label: name,
      description,
      directions,
      hidden: visibility === visibilityValues.HIDDEN,
    },
  };
};

const normalizeField = (
  formValues: FormifiedFieldSchema,
  shouldPresentNewServiceKey: boolean = false
): FormifiedFieldSchema => {
  const updates = [];

  if (shouldPresentNewServiceKey && !formValues.key && !formValues.source) {
    updates.push([values => !values.key, 'key', $set(NEW_SERVICE_FIELD)]);
  }

  if (!shouldPresentNewServiceKey && formValues.key === NEW_SERVICE_FIELD) {
    updates.push(['key', $set('')]);
  }

  updates.push([
    values => values.type === 'dict',
    'options',
    $apply(options => ({ ...options, list: false })),
  ]);

  return update(updates, formValues);
};

const normalizeFormFields = (
  formFields: FormFieldTemplate[],
  formValues: FormifiedFieldSchema
) => {
  return formValues.type === 'dict'
    ? update(
        [
          $each,
          ({ key }) => key === 'options',
          'fields',
          [
            $each,
            ({ key }) => key === 'list',
            $apply(field => ({ ...field, disabled: true })),
          ],
        ],
        formFields
      )
    : formFields;
};

const transformServiceToSettingsForm = (service: AppService) => {
  const { key = '', noun = '', display = {} } = service;
  const {
    label = '',
    directions = '',
    description = '',
    hidden = false,
  } = display;

  return {
    key,
    noun,
    name: label,
    description,
    directions,
    visibility: hidden ? visibilityValues.HIDDEN : visibilityValues.SHOWN,
  };
};

const transformFormToSettings: (
  values: FormifiedSettings
) => ServiceSettings = _.flow(mapFormToSettings, removeEmpties);

const deriveOutputFields = ({
  outputFields,
  sample = {},
}: {
  outputFields?: Object[];
  sample?: {};
}) => {
  const bareOutputFields = Object.entries(sample).reduce(
    (result: Array<FieldSchema>, [key, value]) => {
      let derivation;
      if (_.isPlainObject(value)) {
        derivation = deriveOutputFields({
          sample: value,
        }).map(field => ({ key: `${key}__${field.key}` }));
      } else if (_.isArray(value)) {
        derivation = _.chain(value)
          .flatMap(valueElem => deriveOutputFields({ sample: valueElem }))
          .map(field => ({ key: `${key}[]${field.key}` }))
          .value();
      } else {
        derivation = [{ key }];
      }

      result = _.unionBy(result, derivation, 'key');
      return result;
    },
    []
  );

  return outputFields
    ? _.map(bareOutputFields, field => {
        const outputField: Object =
          _.find(outputFields, { key: field.key }) || {};
        return {
          ...field,
          ...outputField,
        };
      })
    : bareOutputFields;
};

const areAuthFieldsRequired = (auth: AuthenticationSchema) => {
  const authTypesRequiringFields = [
    AuthenticationType.custom,
    AuthenticationType.session,
  ];

  return authTypesRequiringFields.includes(auth.type);
};

const authMeetsRequirements = (auth: AuthenticationSchema) => {
  if (areAuthFieldsRequired(auth)) {
    return !_.isEmpty(auth.fields);
  }

  return true;
};

const isAuthSetupComplete = (auth: AuthenticationSchema) => {
  const hasTest =
    !!_.get(auth, ['test', 'source']) || !!_.get(auth, ['test', 'url']);

  return _.isEmpty(auth) || (authMeetsRequirements(auth) && hasTest);
};

const isActionType = (serviceType: ServiceType) =>
  Object.keys(ACTION_TYPES).includes(serviceType);

const createKeyFromService = (service: ServiceType) =>
  isActionType(service) ? 'actionKey' : `${singular(service)}Key`;

const parseBackendAppToAppType = (app: BackendAppType): AppType => {
  const statusName = normalizeStatusName(app.status);
  const status = getStatus(statusName);

  return {
    id: app.id,
    image: app.image,
    title: app.title,
    isBeta: app.status === APP_STATUSES.beta.name,
    isPublic: app.status === APP_STATUSES.public.name,
    isPending: app.status === APP_STATUSES.pending.name,
    status,
    version: app.latest_version,
    versions: app.versions,
    selectedApi: `${app.key}${KEY_SUFFIX}`,
  };
};

const getValidAppIntention = (
  intention: string
): typeof APP_INTENTION[keyof typeof APP_INTENTION] =>
  Object.values(APP_INTENTION).find(i => i === intention) ||
  APP_INTENTION.emptyString;

const parseBackendAppToAppFormType = (app: BackendAppType): AppFormType => ({
  title: app.title,
  description: app.description,
  homepage_url: app.homepage_url,
  intention: getValidAppIntention(app.intention),
  app_category: app.app_category,
  app_category_other: app.app_category_other,
  image: app.image,
  role: app.role,
  subscription: app.subscription,
});

const buildPartialDefinition = (
  objectType: ObjectType,
  objectKey: string,
  operationMethod: OperationMethodType,
  request: RequestSchema
): PartialDefinition => {
  if (objectType === 'authentication') {
    return {
      [objectType]: {
        [operationMethod]: request,
      },
    };
  }

  const definitionKey =
    objectType === 'search' ? `${objectType}es` : `${objectType}s`;

  return {
    [definitionKey]: {
      [objectKey]: {
        operation: {
          [operationMethod]: request,
        },
      },
    },
  };
};

export {
  authMeetsRequirements,
  buildPartialDefinition,
  createKeyFromService,
  deriveOutputFields,
  setReadOnly,
  flattenOptions,
  getDynamicInput,
  getInputField,
  getInputFieldIndex,
  getInputFields,
  getPerformFromService,
  getValidAppIntention,
  isActionType,
  isAuthSetupComplete,
  normalizeField,
  normalizeFormFields,
  parseBackendAppToAppType,
  parseBackendAppToAppFormType,
  transformFormToSettings,
  transformInputToFormatic,
  transformInputToSchema,
  transformServiceToSettingsForm,
  updateServiceInputFields,
};
