import { addTruthyParamsToUrl } from '@zapier/url-utils';
import {
  commonErrors as Errors,
  resourceNames,
} from '@zapier/toolbox-redux-framework';
import {
  fetch,
  fetchJson,
  postJson,
  putJson,
} from '@zapier/toolbox-browser-fetch';

import {
  ZAPIER_APP_API_PATH,
  ZAPIER_APP_V1_API_PATH,
  FORM_TYPES,
} from 'app/developer-v3/constants';

import type {
  ObjectType,
  OperationMethodType,
  PartialDefinition,
} from 'app/developer-v3/types';
import type { TestData } from 'app/developer-v3/components/RequestTester';
import { TaskData } from 'app/developer-v3/types/task';
import { ZapData } from 'app/entities/Zap/types';

const Data = {
  getAppFormFields: (formId: FORM_TYPES) => {
    const url = `/api/developer/v2/forms/${formId}`;
    return fetchJson(url);
  },
  saveAppImage: (integrationId: number, imageFile: File) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/image`;

    const formData = new FormData();
    formData.append('image', imageFile);

    return fetch(url, { method: 'POST', body: formData })
      .then(fetch.success)
      .then(fetch.json);
  },
  promoteAppVersion: async (
    integrationId: number,
    versionNumber: string,
    changelog?: string
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${versionNumber}/promote/production`;
    return putJson(url, {
      changelog,
    });
  },
  deprecateAppVersion: async (
    integrationId: number,
    versionNumber: string,
    date: string
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${versionNumber}/deprecate`;
    return putJson(url, {
      deprecation_date: date,
    });
  },
  setAsLegacyAppVersion: async (
    integrationId: number,
    versionNumber: string
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${versionNumber}/legacy`;
    return putJson(url);
  },
  migrateAppVersion: async (
    integrationId: number,
    fromVersionNumber: string,
    toVersionNumber: string,
    options: { percentage?: number; email?: string }
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${fromVersionNumber}/migrate-to/${toVersionNumber}`;
    const { percentage, email } = options;

    return postJson(url, { percent: percentage, user: email });
  },
  cloneAppVersion: async (
    integrationId: number,
    fromVersionNumber: string,
    toVersionNumber: string
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${fromVersionNumber}/clone-to/${toVersionNumber}`;
    return postJson(url, { is_ui: true });
  },
  executeTestRequest: (
    integrationId: number,
    versionNumber: string,
    objectType: ObjectType,
    objectKey: string,
    partialDefinition: PartialDefinition,
    authenticationId: string,
    operationMethod: OperationMethodType,
    testData: TestData
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${versionNumber}/test`;
    return postJson(url, {
      objectType,
      objectKey,
      partialDefinition,
      authenticationId,
      operationMethod,
      testData,
    });
  },
  batchUpdateEnvironment: (
    integrationId: number,
    appVersion: string,
    env: Object
  ) => {
    const url = `${ZAPIER_APP_API_PATH}/${integrationId}/versions/${appVersion}/multi-environment`;
    return postJson(url, env);
  },
  loadTasks: async (queryParams: {}): Promise<TaskData[]> => {
    const now = new Date();
    const toDate = new Date(now);
    toDate.setDate(now.getDate() + 1); // Add some leeway to find all tasks.
    const url = addTruthyParamsToUrl('/api/zap-history/v2/runs', {
      to_date: toDate.toISOString(),
      ...queryParams,
    });
    try {
      const response = await fetchJson(url);
      return response.results ?? [];
    } catch (error) {
      throw new Errors.DataGetError({
        resource: resourceNames.tasks,
        response: error,
      });
    }
  },
  loadZaps: async (
    queryParams?: Record<string, string | Number>
  ): Promise<ZapData[]> => {
    const url = addTruthyParamsToUrl('/api/v4/zaps', queryParams);
    try {
      const response = await fetchJson(url);
      return response.results ?? [];
    } catch (error) {
      throw new Errors.DataGetError({
        resource: resourceNames.zap,
        response: error,
      });
    }
  },
  loadAppEvents: (integrationId: string | number, filters: {}) => {
    const apiUrl = `${ZAPIER_APP_V1_API_PATH}/apps/${integrationId}/events`;

    const url = fetch.url(apiUrl, filters);
    return fetchJson(url)
      .then(results => {
        return results?.events || [];
      })
      .catch(response => {
        throw new Errors.DataGetError({
          resource: resourceNames.devAppEvents,
          response,
        });
      });
  },
};

export default Data;
